<template>
  <div @mousedown="startDrag" class="drag-wrapper">
    <slot>
      <div class="drag-content" :style="`transform: translateY(${transY - 5}px);`"></div>
    </slot>
  </div>
</template>

<script>
import {throttle} from '@/common/utils'
export default {
  props: {
    debounce: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      transY: 0
    }
  },
  created() {
    if (this.debounce) {
      this.d_onDrag = throttle(this.debounce, this.onDrag)
    } else {
      this.d_onDrag = this.onDrag
    }
  },
  methods: {
    startDrag(e) {
      this.startX = e.clientX
      this.startY = e.clientY
      console.log('start-drag')
      this.$emit('start-drag')
      window.addEventListener('mousemove', this.d_onDrag)
      window.addEventListener('mouseup', this.endDrag)
      document.body.onselectstart = () => { return false }
      document.body.ondragstart = () => { return false }
    },
    onDrag(e) {
      let dx = e.clientX - this.startX
      let dy = e.clientY - this.startY
      this.$emit('on-drag', { x: dx, y: dy })
      this.transY = dy
    },
    endDrag(e) {
      let dx = e.clientX - this.startX
      let dy = e.clientY - this.startY
      console.log('end-drag')
      this.$emit('end-drag', { x: dx, y: dy })
      window.removeEventListener('mousemove', this.d_onDrag)
      window.removeEventListener('mouseup', this.endDrag)
      document.body.onselectstart = null
      document.body.ondragstart = null
      this.transY = 0
    }
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.d_onDrag)
    window.removeEventListener('mouseup', this.endDrag)
    document.body.onselectstart = null
    document.body.ondragstart = null
  }
}
</script>

<style lang="scss" scoped>
.drag-wrapper {
  cursor: row-resize;
  .drag-content {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 10px;
    transform: translateY(-5px);
    &:hover {
      background-color: rgba(0, 0, 0, .1);
    }
  }
}
</style>
