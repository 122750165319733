import { setMapPosition, getMapPosition } from '@/common/cache'
import { userId } from '../../store/getters'

let mixin = {
  methods: {
    saveMapPosition() {
      const position = this.map.getCenter()
      const zoom = this.map.getZoom()
      const text = `${(position.getLng && position.getLng()) || position.lng()},${(position.getLat && position.getLat()) || position.lat()},${zoom},${this.loginInfo.UserID}`
      console.log(text,"数据")
      setMapPosition(text)
      this.$message && this.$message({
        message: this.$t ? this.$t('onlineMonitor.saveMapInfo') : '保存地图位置成功',
        type: 'success'
      })
    },
    loadPosition(type) {
      let center = '88.357837,36.105937,3'
      if (type === 'google') {
        center = '-110.109798,53.973601,2'
      }
      const text = getMapPosition() ? getMapPosition() : center
      return {
        center: [parseFloat(text.split(',')[0]), parseFloat(text.split(',')[1])],
        zoom: parseInt(text.split(',')[2])
      }
    }
  }
}

export default mixin
